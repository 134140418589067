export enum Challenges {
  enableMembersInvitation = 'specs.challenges.EnableMembersInvitation',
  showStepShare = 'specs.challenges.WebMemberShowStepShare',
  enableSlugsInUrl = 'specs.challenges.WebUseSlugsInURL',
  renderSeoTags = 'specs.challenges.WebEnableRenderSeoTags',
  isHiddenStepsEnabled = 'specs.challenges.OOIEnableHiddenAndLockSteps',
  muteErrorToastNotifications = 'specs.challenges.MuteErrorToastNotifications',
  enableConcurrentMode = 'specs.challenges.OOIEnableConcurrentMode',
  enableHappyScreens = 'specs.challenges.WebMemberHappyScreens',
  enableNewSidebarScrollingBehaviour = 'specs.challenges.WebNewSidebarScrollingBehaviour',
  enableFullscreenMode = 'specs.challenges.WebSidebarFullscreenMode',
  enableServerless = 'specs.challenges.OOIEnableServerlessAPI',
  enableServerlessChallenge = 'specs.challenges.OOIEnableServerlessAPIChallenge',
  enableVisibilityByName = 'specs.challenges.OOIEnableVisibilityByName',
  enableAutopilot = 'specs.challenges.BMEnableAutopilot',
  enableAutopilotADI = 'specs.challenges.BMEnableAutopilotADI',
  enableAutopilotEditorX = 'specs.challenges.BMEnableAutopilotEditorX',
  enableDripContent = 'specs.challenges.WebMemberDripContent',
  enableOverviewStartButton = 'specs.challenges.WebOverviewStartButton',
  enableBadgeRewardForSPC = 'WebMemberBadgeAllStepsForSPC',
  isSideListLayoutEnabled = 'specs.challenges.OOIEnableListSideLayout',
  stretchProgramImages = 'specs.challenges.OOIStretchProgramImages',
  enableSideBySideAddon = 'specs.challenges.OOIEnableSideAddon',
}
